import React from "react"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"
import { graphql } from "gatsby"

interface SpecialsProps {
  data: {
    cms: {
      page: {
        title: string
        special_fields: {
          specialsImage: {
            sourceUrl: string
          }
        }
      }
    }
  }
}

export const Specials: React.FC<SpecialsProps> = ({ data }) => {
  console.log(data)
  return (
    <Layout withPadding>
      <div className="flex w-full flex-col items-center overscroll-x-auto">
        <PageTitle>{data.cms.page.title ?? "Specials"}</PageTitle>
        <div className="w-11/12 bg-white">
          <img
            src={data.cms.page.special_fields.specialsImage.sourceUrl}
            alt="Kursplan gerade nicht Verfügbar"
            title="Kursplan"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Specials
export const query = graphql`
  query {
    cms {
      page(id: "/ecials", idType: URI) {
        title
        special_fields {
          specialsImage {
            sourceUrl
          }
        }
      }
    }
  }
`
